import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { LoggerService } from './logger.service';
import { DirtyInService } from './crypt/dirty-in.service';
import { DirtyOutService } from './crypt/dirty-out.service';
import { FeedbackService } from '../notifications/feedback.service';
import { Base64Service } from './base64.service';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from './url.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    public didWork = false;
    public loopShouldRun = true;
    public isRunning = false;
    public lastRefreshTime = 0;
    private subscribeRunning = 0;
    private taskRunning = false;
    public settings: sync.INotificationState;
    private maxHistId = 0;

    private isTaskrunningSharedSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dirtyInService: DirtyInService,
        private dirtyOutService: DirtyOutService,
        private userService: UserService,
        private feedbackService: FeedbackService,
        private loggerService: LoggerService,
        private base64Service: Base64Service,
        private apiService: ApiService,
        private http: HttpClient,
        private urlService: UrlService,
        private broadcastService: BroadcastService
    ) {

        this.settings = {
            maintenance: false,
            user: this.userService.getUser(),
            globalTaskStatus: 0,
            feedback: this.feedbackService.view,
            pendingShares: 0,
            disk_limit_bytes: 0,
            disk_usage_bytes: 0,
            lastRunTime: 0,
            runCount: 1000,
            runCountLimit: 200, // the limit of runCount before we barf.
            errorCount: 0,
            errorCountLimit: 10, // the amount of errors before we barf.
            is_taskrunning: false,
            is_taskrunningShared: false
        };

        if (this.settings.user.uid > 1000000
            && window.SYNCCFG
            && window.SYNCCFG.maintenanceshards
            && window.SYNCCFG.maintenanceshards.length) {
            const shard = this.settings.user.uid % 10000;
            if (window.SYNCCFG.maintenanceshards.indexOf(shard) > -1) {
                this.loggerService.error('Setting user to maintenance readonly due to shards');
                this.settings.maintenance = true;
                this.loopShouldRun = false;
                return;
            }
        }
    }

    public refreshUser() {
        return this.userService.refresh();
    }


    public stopNotificationLoop() {
        this.loggerService.info('Notifications: Stop');
        this.loopShouldRun = false;
    }

    public startNotificationLoop() {
        this.loggerService.info('Notifications: Start');
        this.loopShouldRun = true;
        this.settings.runCount = 0;
        this.runLoop().then(() => this.subscribe());
    }

    public handleLogin() {
        this.feedbackService.hideFeedback();
        this.refreshUser();
        // this.settings.user = this.User.userattr;
        this.startNotificationLoop();
    }

    public handleLogout() {
        this.feedbackService.hideFeedback();
        this.stopNotificationLoop();
    }

    private async runLoop(defer?: Promise<any>, promiseResolver?: any) {
        const promise = defer || new Promise<void>((resolve, reject) => {
            promiseResolver = resolve;
        });
        if (!this.userService.isAuthenticated()) {
            this.loggerService.warn('Not logged in, not running notifications');
            promiseResolver();
        } else if (!this.loopShouldRun) {
            this.loggerService.warn('Notification loop should stop');
            promiseResolver();
        } else if (this.isRunning) {
            this.loggerService.warn('Notifications loop is already running');
            promiseResolver();
        } else {
            this.isRunning = true;
            this.loggerService.info('Running notifications ...');
            const data = await this.apiService.execute('usernotifications', {});
            const didWork = await this.processLoop(data);
            this.isRunning = false;
            if (didWork) {
                this.loggerService.info('More work to do');
                return this.runLoop(promise, promiseResolver);
            } else {
                promiseResolver();
            }
        }
        return promise;
    }

    // process the API result
    private processLoop(data: any): Promise<boolean> {
      return new Promise((resolve, reject) => {
        if (parseInt(data.locked, 10) === 0) {
            this.loggerService.info('User is locked on notifications.');
            // when you're locked, continue trying to run notifications
            resolve(true);
        } else {
            this.settings.disk_limit_bytes = data.disk_limit_bytes;
            this.settings.disk_usage_bytes = data.disk_usage_bytes;
            this.settings.lastRunTime = Date.now();

            this.settings.pendingShares = parseInt(data.amount, 10);

            // console.log(['last chec ',
            // this.User.get('is_verified'), '!=' + data.is_verified,
            // this.User.get('is_pro') , '!=' ,
            // this.User.get('display_name') , '!= ' ,
            // this.Base64.decode(data.display_name)
            // ].join(''));
            (this.userService.get('is_verified') != data.is_verified ||
                this.userService.get('is_pro') != data.is_pro ||
                this.userService.get('display_name') !=  data.display_name ||
                this.userService.get('sku') != data.sku)
                ? this.userService.refresh() // force refresh
                : this.userService.update(); // refresh if >5 min since last

            // refresh views if possible.
            this.loggerService.info([
                'Checking last refresh:',
                new Date(this.lastRefreshTime),
                'greater than 5s:', (this.lastRefreshTime < (Date.now() - 5000)),
                'maxhistid:', this.maxHistId,
                'hist_id received:', data.hist_id
            ].join(' '));

            if (this.lastRefreshTime == 0
                || this.lastRefreshTime < (Date.now() - 5000)
                || this.maxHistId != data.hist_id
            ) {
                this.lastRefreshTime = Date.now();
                // TODO this may be causing double refreshes on load.
                this.broadcastService.broadcast('event:file-list.reload', {hist_id: data.hist_id});
                this.broadcastService.broadcast('event:link-list.reload');
                this.broadcastService.broadcast('event:share-list.reload');
                this.maxHistId = data.hist_id;
                this.runTaskLoop();
            } else {
                this.loggerService.info(`skip Refreshing lists ${this.lastRefreshTime}`);
            }


            if (this.settings.errorCount > this.settings.errorCountLimit) {
                this.loggerService.error('Notifications encountered 50+ errors.');
                this.loggerService.error('Stopping notifications on account of errors');
                this.stopNotificationLoop();
                this.settings.is_taskrunning = false;
                resolve(this.settings.is_taskrunning);
                return;
            }
            if (this.settings.runCount > this.settings.runCountLimit) {
                this.loggerService.error(`More flag has run >= ${this.settings.runCountLimit} without processing anything`);
                this.loggerService.error(JSON.stringify(data));
                this.settings.is_taskrunning = false;
                this.stopNotificationLoop();
                resolve(this.settings.is_taskrunning);
                return;

            }
            if (data.task_dirtyin && data.task_dirtyin.cnt) {
                // process dirty in first
                this.settings.runCount = 0;
                this.settings.is_taskrunning = true;
                this.settings.is_taskrunningShared  = true;
                this.isTaskrunningSharedSubject.next(this.settings.is_taskrunning);
                this.loggerService.info('DirtyIn is running: ' + data.task_dirtyin.cnt);
                this.dirtyInService.processDirtyIn(data.task_dirtyin).
                    then(() => {
                        this.loggerService.info('Completed dirty in, running again');
                        resolve(this.settings.is_taskrunning);
                    }).catch((err) => {
                        this.loggerService.error('DirtyIn failed');
                        this.loggerService.error(JSON.stringify(data.task_dirtyin));
                        this.settings.errorCount += 1;
                        this.processLoopError(err);
                        resolve(this.settings.is_taskrunning);
                    });
            } else if (data.task_dirtyout && data.task_dirtyout.length) {
                // process dirty out
                // reset run count since there was work to be done.
                this.settings.runCount = 0;
                this.settings.is_taskrunning = true;
                this.settings.is_taskrunningShared  = false;
                this.isTaskrunningSharedSubject.next(this.settings.is_taskrunning);
                this.loggerService.info('DirtyOut is running = ' + data.task_dirtyout.length);
                this.dirtyOutService.processDirtyOut(data.task_dirtyout)
                    .then(() => {
                        this.loggerService.info('Completed dirty out, running again');
                        resolve(this.settings.is_taskrunning);
                    }, (err) => {
                        this.loggerService.error('Dirty out failed');
                        this.loggerService.error(JSON.stringify(data.task_dirtyout));
                        this.loggerService.error(err);
                        this.settings.errorCount += 1;
                        resolve(this.settings.is_taskrunning);
                    });

            } else if (data.more) {
                // if the data.more flag is set, it means there is more work
                this.loggerService.info('More flag was set, re-run notifications');
                this.settings.runCount += 1;
                if (this.settings.runCount >= this.settings.runCountLimit) {
                    this.loggerService.error(`More flag has run >= ${this.settings.runCountLimit} without processing anything`);
                    this.loggerService.error(JSON.stringify(data));
                    this.settings.is_taskrunning = false;
                    this.settings.is_taskrunningShared  = false;
                    this.isTaskrunningSharedSubject.next(this.settings.is_taskrunning);
                    this.stopNotificationLoop();
                } else {
                    this.settings.is_taskrunning = true;
                    this.isTaskrunningSharedSubject.next(this.settings.is_taskrunning);
                }
                resolve(this.settings.is_taskrunning);
            } else {
                // more flag is not set, setting run count to 0
                this.settings.runCount = 0;
                this.settings.is_taskrunning = false;
                this.settings.is_taskrunningShared  = false;
                this.isTaskrunningSharedSubject.next(this.settings.is_taskrunning);
                resolve(this.settings.is_taskrunning);
            }
        }
      });
    }

    /**
     * If an error occurs with notifications, do not die.  Attempt to re-run
     * it again after 5 seconds.  Log the error to Logger service.
     * @param  {Object} data [description]
     */
    private async processLoopError(data: any): Promise<boolean> {
        this.settings.is_taskrunning = true;
        this.loggerService.error('An error occurred processing dirty in or out');
        this.loggerService.error(data);
        return Promise.resolve(this.settings.is_taskrunning);
        // loopTO = $timeout(runLoop, 5000);
    }


    private subscribe() {
        this.loggerService.info('Notifications.subscribe()');
        if (!this.userService.isAuthenticated()) {
            this.loggerService.info('Polling is disabled until authenticated');
            this.subscribeRunning = 0;
        } else if (!this.loopShouldRun) {
            this.loggerService.info('Polling is disabled due to a "stopNotificationLoop call');
        } else if (this.subscribeRunning != 0) {
            this.loggerService.info('A subscribe thread is already running');
        } else {
            this.subscribeRunning = Date.now();
            return this.subscribeNotifications()
                .then(() => {
                    this.loggerService.info(`Subscribe awoken ${(Date.now() - this.subscribeRunning)} ms`);
                    return new Promise<void>(async (resolve, reject) => {
                        this.runLoop().finally(() => {
                            this.loggerService.info('Subscribe runLoop finally');
                            this.subscribeRunning = 0;
                            this.subscribe();
                            resolve();
                        });
                    });
                });
        }
    }

     /**
     * @ngdoc method
     * @name  subscribe
     * @description
     * Subscribes to the long poll on API.  The long poll awakens every
     * 10 minutes, or alternatively is woken up when something changes.
     *
     * Notifications will determine whether or not to run the UserNotifications
     * and process any data.
     * @see  sync.service:Notifications
     * @return {Promise} Resolves when woken up either from a change on the
     *                   acct or after 10 minutes.
     */
    public subscribeNotifications(): Promise<any> {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '660000'
        });
        return new Promise<void>(async (resolve, reject) => {
            this.http.get(this.urlService.mkSubscribe(), { headers, responseType: 'text' })
                .subscribe(() => resolve(), () => {
                    return resolve();
                });
        });
    }

    public runTaskLoop() {
        if (!this.userService.isAuthenticated()) {
            this.loggerService.warn('Not logged in, not running notifications');
            return;
        } else if (!this.loopShouldRun) {
            this.loggerService.warn('Notification loop should stop');
            return;
        } else if (this.isRunning) {
            this.loggerService.warn('Notifications loop is already running');
            return;
        } else if (!this.taskRunning) {
            this.taskRunning = true;
            this.apiService.execute<any>('getrewind', {})
                .then((result) => {
                    this.settings.globalTaskStatus = parseInt(result.job_status_id, 10);
                    this.taskRunning = false;
                    if (!result || !result.job_status_id) {
                        // job is done
                        this.settings.globalTaskStatus = 0;
                    } else if (result.job_status_id == 1 || result.job_status_id == 2) {
                        setTimeout(() => {
                            this.runTaskLoop();
                        }, 10000);
                    }
                });
        }
    }


    public getNotificationStatus(): Observable<boolean> {
        return this.isTaskrunningSharedSubject;
    }
}
