import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BlendEvent, ErrCode, User } from '../../shared/models';
import { LoggerService } from '../../core/logger.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { FileItemService } from '../file-item.service';
import { TransferItemService } from '../../transfer/services/transfer-item.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/api.service';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { BlendService } from '../../shared/services/blend.service';
import { FinishBackupApiInput } from '../../shared/models/api/finishbackup-api.model';
import { FileUploader } from '../../transfer/file-uploader';

type msOperations = 'embedview' | 'edit' | 'view';
type msPrograms = 'Word' | 'Excel' | 'PowerPoint' | 'WopiTest';

interface WopiInitInput {
    file: {
        file_id: string;
        cachekey: string;
        datakey_old: string;
        filename: string;
        filesize: number;
        timestamp: number;
        version?: number;
        owner_id: number;
        mode: 'legacy' | 'ultimate';
    };
    user: {
        friendly_name: string;
        user_id: number;
        user_email: string;
        device_id: number;
        readonly: boolean;
        is_business: boolean;
        datakey_new: string;
        dump: any;

        parent_dir_url: string;
        parent_dir_name: string;
        edit_url: string;
        view_url: string;
        msprogram: msPrograms;
        action: msOperations;
        mode: 'legacy' | 'ultimate' | 'link';
        sync_id?: number;
        sync_pid?: number;
        share_id?: number;
        share_key?: string;
        share_key_id?: string;
        linkPasswordLock?: string;
        linkPassword?: string;
        linkId?: string;
    };
}

interface WopiInitOutput {
    actionurl: string;
    faviconurl: string;
    access_token: string;
    access_token_ttl: number;
}

@Component({
    selector: 'sync-preview-wopi',
    templateUrl: './preview-wopi.component.html',
})
export class PreviewWopiComponent implements OnInit, OnDestroy {
    // Important environment for providing to WOPI
    public wopiUrl = environment.wopihost + '/wopi/init/';
    public hostUrl = environment.currenthost;

    public errcode: ErrCode;
    public spinner = true;
    @Output() public stateChange = new EventEmitter<any>();

    // Binding from component.
    public type: 'office' | 'officefse' | 'officefsv';
    @Input() public item: sync.IFile;

    public allowComment: 0 | 1 | 2;
    public isCommentExpanded: boolean;

    // Initialize values, to POST to Wopiinit
    private isReadOnly = false;
    private currentUser: User;
    private masterlinks_id: string;
    private wopiFileId: string;
    private downloadItem: sync.ITransferItemDownload;
    private uploadItem: sync.ITransferItemUpload;
    private webfinishbackup: any;
    private msOperation: msOperations;
    private msProgram: msPrograms;
    private wopiInitReq: WopiInitInput;
    private wopiInitResp: WopiInitOutput;

    private initialized = false;

    public viewUrl: string;
    private inLink: boolean;
    private timeout = 15000; // Timeout the entire operation after 15s
    private faviconurl = '';
    private faviconurl_original = '';
    private startTime: number;
    private iFrameTime: number;
    private sub: Subscription;
    private linkPassword: string;
    public isUploadAllowed: number;
    public user: User;
    private userSubscribe: Subscription;
    public isFileEditAllowed: number;
    public isCwdShared: number;
    public compat = 0;

    private fileUploader: FileUploader;

    constructor(
        private loggerService: LoggerService,
        private linkPathListService: LinkFileListService,
        private fileItemService: FileItemService,
        private transferItemService: TransferItemService,
        private http: HttpClient,
        private apiService: ApiService,
        private buildTransferItemService: BuildTransferItemService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<fromRoot.State>,
        private blendService: BlendService,
        private injector: Injector,
    ) {
        this.fileUploader = new FileUploader(this.injector);
    }

    async ngOnInit() {
        this.linkPassword = this.route.snapshot.params['key'] || this.route.snapshot.fragment;
        try {
            // Set a timeout for the entirety of the wopi initialization operation
            this.startTime = Date.now();
            this.userSubscribe = this.store
                .pipe(select(fromRoot.getAuthUser))
                .subscribe((data) => {
                    if (data) {
                        this.user = data;
                    } else {
                        this.user = null;
                    }
                });

            this.sub = this.linkPathListService
                .getSubscription()
                .subscribe((data) => {
                    if (data.loaded && data.sorted) {
                        this.isUploadAllowed = data.upload;
                        this.isFileEditAllowed = data.file_edit;
                        this.allowComment = data.allow_comment;
                        this.isCwdShared = data.cwd.is_shared;
                        this.compat = data.compat;
                    }
                });
            setTimeout(() => {
                if (this.spinner && !this.errcode) {
                    this.spinner = false;
                    throw new ErrCode(
                        11006,
                        'Request timed out. Please contact support'
                    );
                }
            }, this.timeout);

            this.type = this.route.snapshot.params['type'];

            // Init all of the required data, and then create the request payload
            this.inLink = this.item.context !== 'files';
            const wopiFileExt = this.fileItemService.getFileExt(this.item.name);

            if (this.type === 'officefse') {
                this.blendService.track(BlendEvent.EDIT_FILE_OFFICE, {
                    type: wopiFileExt,
                    platform: this.inLink ? 'Link' : 'CP',
                    filesize: this.item.filesize || this.item.size + ' bytes',
                    mimeType: this.item.mime_type
                });
            } else {
                this.blendService.track(BlendEvent.VIEW_FILE, {
                    type: wopiFileExt,
                    preview_preference: 'Office',
                    platform: this.inLink ? 'Link' : 'CP',
                    filesize: this.item.filesize || this.item.size + ' bytes',
                    mimeType: this.item.mime_type
                });
            }
            await this.init();
            this.wopiInitReq = await this.buildRequest();
            this.loggerService.info(
                'Built Wopi Request, sending to ' + this.wopiUrl
            );
            this.loggerService.info(this.wopiInitReq);
            // Send the request to Wopi
            this.wopiInitResp = await this.wopiInit(this.wopiInitReq);
            this.loggerService.info(
                'Recieved successful response from Wopi. Attempting to pass to MS.'
            );
            this.loggerService.info(this.wopiInitResp);
            // Submit the data received from Wopi to MS
            await this.buildAndSubmitForm();
            this.spinner = false;
        } catch (ex) {
            this.loggerService.error(ex);

            if (ex instanceof ErrCode) {
                this.errcode = ex;
            } else {
                this.errcode = new ErrCode(
                    11000,
                    'Microsoft Preview unable to load'
                );
            }
            this.spinner = false;
        }
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.userSubscribe) {
            this.userSubscribe.unsubscribe();
        }
        document
            .getElementsByName('defaultfavicon')[0]
            .setAttribute('href', this.faviconurl_original);
    }

    private unauthUserInLink(): boolean {
        return this.inLink && !this.user;
    }

    private allowLinkEdit(): boolean {
        if (this.inLink) {
            return this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared);
        }
        return true;
    }

    public async buildAndSubmitForm(): Promise<void> {
        // Grabs query params and strips them from URL.
        const queryParams = this.route.snapshot.queryParams;
        let MSQueryParams = '';
        for (const key of Object.keys(queryParams)) {
            MSQueryParams += `${key}=${queryParams[key]}&`;
        }

        // do not strip queryParams from url in publink, as we require sync_id in publinks for linkpathlist
        this.inLink ?
            this.router.navigate([], {
                relativeTo: this.route,
                ...this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams),
                ...this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)
            })
            : this.router.navigate([], { relativeTo: this.route, queryParams: {} });

        // TODO: Is there a better way to set the faviconurl?
        this.faviconurl = this.wopiInitResp.faviconurl;
        if (this.type !== 'office' && this.faviconurl) {
            this.faviconurl_original = document
                .getElementsByName('defaultfavicon')[0]
                .getAttribute('href');
            document
                .getElementsByName('defaultfavicon')[0]
                .setAttribute('href', this.faviconurl);
        }
        this.iFrameTime = Date.now();

        const actionUrl = this.wopiInitResp.actionurl + '?' + MSQueryParams;
        this.loggerService.info(`Posting to "${actionUrl}"`);
        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('id', 'office_form');
        form.setAttribute('name', 'office_form');
        form.setAttribute('target', 'office_frame');
        form.setAttribute('action', actionUrl);

        const field1 = document.createElement('input');
        const field2 = document.createElement('input');
        field1.setAttribute('name', 'access_token');
        field1.setAttribute('value', this.wopiInitResp.access_token);
        field2.setAttribute('name', 'access_token_ttl');
        field2.setAttribute(
            'value',
            this.wopiInitResp.access_token_ttl.toString()
        );

        form.appendChild(field1);
        form.appendChild(field2);

        document.body.appendChild(form);
        if (!this.errcode) {
            form.submit();
        }
        document.body.removeChild(form);

        const sendTime = Date.now();
        const message = {
            MessageId: 'Host_PerfTiming',
            SendTime: sendTime,
            Values: {
                Click: this.startTime,
                Iframe: this.iFrameTime,
                HostFrameFetchStart: 0,
                RedirectCount: 0,
            },
        };

        const iframe = document.getElementById('office_frame');
        const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
        iWindow.postMessage(message, window.parent.origin);
        this.loggerService.info(JSON.parse(JSON.stringify(message)));
    }

    public async init(): Promise<void> {
        if (this.type === 'office' || (this.unauthUserInLink())) {
            this.msOperation = 'embedview';

            // NOTE: Key is only checked for presence, if we're worried about singular data/cachekey combos leaking
            //       We can add the ability to check the JWT token on wopi's side.
            const keys = await this.buildTransferItemService.getFileKeys([this.item]);
            this.loggerService.warn(keys);
            if (keys && (!keys.previewtoken || keys.previewtoken == '')) {
                this.loggerService.info(
                    'No preview token available ' + this.item.sync_id
                );
                this.stateChange.emit({ type: 'default', upsell: true });
                return;
            }
        } else if (this.type === 'officefse') {
            this.msOperation = 'edit';
        } else if (this.type === 'officefsv') {
            this.msOperation = 'view';
        } else {
            this.loggerService.error('Unknown command type hit');
            throw new ErrCode(
                11009,
                'This action cannot be performed on this file.'
            );
        }

        if (!this.allowLinkEdit()) {
            // WHEN LINKS ARE ENABLED (in the future)
            // If the user is in a link we want to:
            // 1. Let them work on local and shares (but only in the publink layer)
            // 2. Let them use actions: embedded
            // 3. Ignore their authentication, if present

            if (this.router.url.includes('/dl')) {
                const navigationParams = ['/dl', this.route.snapshot.params['cachekey'], 'view', 'doc', this.item.sync_id];
                // add key in navigation params array if it exists in router params, otherwise key after # in fragment will be used
                if (this.route.snapshot.params['key']) { navigationParams.splice(2, 0, this.route.snapshot.params['key']); }
                this.router.navigate(navigationParams, {
                    ...this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams),
                    ...this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)
                });
            }
            this.loggerService.error('User attempted to open a file in a link');
            this.errcode = new ErrCode(
                11010,
                'User attempted to open a file in a link'
            );
            throw this.errcode;
        } else {
            // If the user is authenticated we want to:
            // 1. Let them work on local and shares (in view/edit), regardless of publinks
            // 2. Let them use actions: embedded, view and edit

            // Ensure the users's path is not too dirty
            if (this.item.is_shared) {
                const retries = 3;
                const delay = 3000;

                for (let i = retries; i > 0; i--) {
                    const res = await this.apiService.execute<any>('pathdirtycheck', {
                        sync_id: this.item.sync_id,
                        share_id: this.item.share_id
                    });

                    if (res.status === 0) {
                        this.loggerService.error(
                            `Users path is dirty, waitng ${delay}ms and retrying ${i} more times.`
                        );
                        await new Promise((resolve) =>
                            setTimeout(resolve, delay)
                        );
                    } else {
                        this.masterlinks_id = res.masterlinks_id;
                        break;
                    }
                }

                if (!this.masterlinks_id) {
                    this.loggerService.error(
                        `Couldn't clean users path after ${retries} attempts.`
                    );
                    throw new ErrCode(
                        11001,
                        'This file is too out of date to work on collaboratively; please wait or contact support.'
                    );
                }
            }

            this.uploadItem = await this.getUpload(this.item);
            this.loggerService.info('UploadItem');
            this.loggerService.info(this.uploadItem);
            this.loggerService.info(
                [
                    'Wopi upload item',
                    'syncPId:',
                    this.uploadItem.sync_pid,
                    'mime:',
                    this.uploadItem.mimetype,
                    'size:',
                    this.uploadItem.filesize,
                    'date:',
                    this.uploadItem.filedate,
                ].join(' ')
            );
            if (!this.inLink) { this.webfinishbackup = await this.getDump(this.item, this.uploadItem); }
            this.isReadOnly = Boolean(
                this.item.is_shared && this.item.is_readonly
            );
        }

        // TODO: We may want user data for people accessing links at some point. This might do it:
        // this.currentUser = this.userService.isAuthenticated() ? this.userService.getUser() : undefined;
        this.currentUser = this.unauthUserInLink() ? undefined : this.user;

        this.downloadItem = await this.getDownload(this.item);
        this.loggerService.info('DownloadItem');
        this.loggerService.info(this.downloadItem);
        this.loggerService.info(
            [
                'Wopi download item: ',
                'syncId:',
                this.downloadItem.sync_id,
                'blobType:',
                this.downloadItem.blobtype,
                'mime:',
                this.downloadItem.mimetype,
                'size:',
                this.downloadItem.filesize,
                'date:',
                this.downloadItem.filedate,
            ].join(' ')
        );

        // this.serverUrls = await this.getServers(this.item);
        this.wopiFileId = this.getWopiFileId(this.item, this.currentUser);

        this.wopiUrl = this.wopiUrl + this.wopiFileId;
        const app = this.fileItemService.getMSOfficeApp(this.item);
        if (app === 'None') {
            this.loggerService.error(
                'User attempted to open a filetype not compatible with office: ' +
                    this.item.name
            );
            throw new ErrCode(
                11010,
                'This file is not compatible with Office 365'
            );
        } else {
            this.msProgram = app;
        }

        this.initialized = true;
        this.loggerService.info('Init Complete');
    }

    private async getDownload(
        item: sync.IFile
    ): Promise<sync.ITransferItemDownload> {
        if (this.inLink) {
            return this.buildTransferItemService.mkDownloadItem(
                item,
                await this.buildTransferItemService.getFileKeys([item])
            );
        } else {
            return this.buildTransferItemService.mkDownloadItem(
                item,
                await this.buildTransferItemService.getFileKeys([item])
            );
        }
    }

    private async getUpload(
        item: sync.IFile
    ): Promise<sync.ITransferItemUpload> {
        let uploadItem = <sync.ITransferItemUpload>(
            await this.transferItemService.get({
                type: this.transferItemService.TYPE_UPLOAD,
                sync_pid: item.pid,
                filename: item.name,
                filesize: 1,
                fileobj: undefined,
                status: 0,
                filedate: Date.now(),
            })
        );

        uploadItem = await this.fileUploader.initSha1Digest(uploadItem);
        uploadItem.sync_pid = item.pid;
        uploadItem.share_id = item.share_id;
        uploadItem.share_sequence = item.share_sequence;

        // sync_id of the file is required to fetch the correct sharekeys for webfinishbackup
        // webfinishbackup is invoked from wopi and the sharekeys are passed from wopi to the api
        uploadItem.sync_id = item.id;
        return uploadItem;
    }

    private async getDump(
        item: sync.IFile,
        upload: sync.ITransferItemUpload
    ): Promise<FinishBackupApiInput> {
        const webfinishbackup = await this.fileUploader.buildFinishBackup(upload);

        webfinishbackup.is_wopi_save = 1;
        return webfinishbackup;
    }

    private getWopiFileId(item: sync.IFile, user: User): string {
        this.loggerService.info('Item and then User');
        this.loggerService.info(item);
        this.loggerService.info(user);
        let wopiFileId;

        if (item.u_id && item.u_id !== '') {
            wopiFileId = item.u_id;
        } else {
            if (this.inLink) {
                // NOTE: A user could have a publink on top of a share.
                // This will /NOT/ collide with that.
                // TODO: Edit collaboratively with people outside of sync will have to choose what to edit on
                // 1. The publink (will make it difficult to also collab with sharers)
                // 2. The webpaths/share (will make it difficult to write from unauth)

                wopiFileId = 'P' + item.link_owner_id + '-' + item.id;
            } else {
                item.is_shared
                    ? wopiFileId = 'S' + item.share_id + '-' + this.masterlinks_id
                    : wopiFileId = 'U' + user.id + '-' + item.id;
            }
        }
        this.loggerService.warn('Wopi FileID defined as "' + wopiFileId + '"');
        return wopiFileId;
    }

    public buildRequest(): WopiInitInput {
        if (!this.initialized) {
            throw new ErrCode(
                11000,
                `Could not make request, file was not properly initialized`
            );
        } else {
            return {
                file: {
                    file_id: this.wopiFileId,
                    cachekey: btoa(this.downloadItem.cachekey),
                    datakey_old: btoa(this.downloadItem.data_key.toString()),
                    filename: this.downloadItem.name,
                    filesize: this.downloadItem.filesize,
                    timestamp: this.item.usertime,
                    // TODO: Does a real owner_id exist?
                    owner_id: this.inLink
                        ? this.item.link_owner_id
                        : this.currentUser.id,
                    mode: 'legacy'
                },
                user: this.inLink
                    ? {
                        friendly_name: this.unauthUserInLink() ? 'Anonymous User' : atob(this.currentUser.display_name) ||
                            this.currentUser.email,
                        user_id: this.unauthUserInLink() ? 0 : this.currentUser.id,
                        user_email: this.unauthUserInLink() ? 'Anonymous User' : this.currentUser.email,
                          // TODO: What are the connotations of a device_id of 0 beyond no write API calls?
                          // Should we use this.currentUser.web_device_id or this.item.event_device_id?
                        device_id: this.unauthUserInLink() ? 0 : this.currentUser.web_device_id,
                        readonly: this.isReadOnly,
                          is_business: true,
                        datakey_new: btoa(
                            this.uploadItem.data_key.toString()
                        ),
                        dump: {},

                          parent_dir_url:
                              this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword,
                          parent_dir_name: 'Back to Sync',
                          view_url:
                              this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword +
                            '/view/officefsv/' + this.item.sync_id,
                          edit_url:
                              this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword +
                            '/view/officefse/' + this.item.sync_id,
                          msprogram: this.msProgram,
                          action: this.msOperation,
                        mode: 'link',
                        sync_id: this.item.sync_id,
                        sync_pid: this.item.pid,
                        share_id: this.item.share_id,
                        share_key: this.item.share_key,
                        share_key_id: this.item.share_key_id,
                        linkPasswordLock: this.item.linkpasswordlock,
                        linkPassword: this.linkPassword,
                        linkId: this.item.linkID,
                      }
                    : {
                          friendly_name:
                              atob(this.currentUser.display_name) ||
                              this.currentUser.email,
                          user_id: this.currentUser.id,
                        user_email:
                            this.currentUser.email,
                          device_id: this.currentUser.web_device_id,
                          readonly: this.isReadOnly,
                          is_business:
                              this.currentUser.is_office_personal !== 1,

                          datakey_new: btoa(
                              this.uploadItem.data_key.toString()
                          ),
                          dump: this.webfinishbackup,

                          parent_dir_url:
                              this.hostUrl +
                              '/files/' +
                              this.item.pid.toString(),
                          parent_dir_name: 'Back to Sync',
                          view_url:
                              this.hostUrl +
                              '/file/' +
                              this.item.sync_id +
                              '/view/officefsv',
                          edit_url:
                              this.hostUrl +
                              '/file/' +
                              this.item.sync_id +
                              '/view/officefse',
                          msprogram: this.msProgram,
                          action: this.msOperation,
                        mode: 'legacy'
                      },
            };
        }
    }

    private wopiInit(requestbody: WopiInitInput): Promise<WopiInitOutput> {
        return new Promise<any>(async (resolve, reject) => {
            this.http.post(this.wopiUrl, requestbody).subscribe(
                (response: any) => {
                    const data = response;
                    if (
                        data &&
                        data['actionurl'] &&
                        data['access_token'] &&
                        data['access_token_ttl']
                    ) {
                        resolve(data as WopiInitOutput);
                    } else {
                        this.loggerService.error(
                            'No data recieved from WOPI, but an error did not occur'
                        );
                        reject(
                            new ErrCode(
                                11004,
                                'The handshake could not be started to initialize the communication with Microsoft'
                            )
                        );
                    }
                },
                (error: any) => {
                    this.loggerService.error(
                        'An error occured sending request to WopiInit'
                    );
                    reject(error);
                }
            );
        });
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }
}
