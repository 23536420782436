import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoggerService } from 'src/app/core/logger.service';
import { LinkFileListService } from 'src/app/link-consume/services/link-file-list.service';
import { environment } from 'src/environments/environment';
import { User, Feature } from '../models';
import { SkuService } from './sku.service';
import * as fromRoot from '../../reducers';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, retryWhen, take, shareReplay } from 'rxjs/operators';
import { MemoizationService } from './memoization.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private path = environment.featureHost;
  private features: Feature[] = [];
  public featureFlag: any;
  private EXPIRY = 43200000; // 12 hours
  private user: User;
  private previousUserId: number;

  private userSubscribe: Subscription;
  private linkSubscribe: Subscription;
  private featureSubscribe: Subscription;
  private featureCache$: Observable<Feature[]> | null = null;

  constructor(
    private httpClient: HttpClient,
    private skuService: SkuService,
    private log: LoggerService,
    private LinkPathList: LinkFileListService,
    private memoizationService: MemoizationService,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {
    this.getFeatureData();
  }

  private async getUser(): Promise<void> {
    if (this.user) { return; }

    if (this.router.url.includes('/dl')) {
      const data = await new Promise<any>((resolve) => {
        this.linkSubscribe = this.LinkPathList.getSubscription()
          .pipe(take(1))
          .subscribe((data) => {
            resolve(data);
          });
      });

      if (data && data.owner_id) {
        this.user = new User({
          id: data.owner_id,
          team_id: data.team_id,
          email: '',
          sku: '',
          is_multi_admin: data.is_multi_admin,
          is_on_trial: data.is_on_trial,
          is_link_user: true,
        });
        this.unsubscribe(this.linkSubscribe);
      }
    } else {
      const data = await new Promise<User | undefined>((resolve) => {
        this.userSubscribe = this.store
          .pipe(select(fromRoot.getAuthUser), take(1))
          .subscribe((data: User) => {
            resolve(data);
          });
      });

      if (data) {
        this.user = data;
        this.unsubscribe(this.userSubscribe);
      }
    }
  }


  private getFeatureData(): void {
    this.unsubscribe(this.featureSubscribe);

    this.featureSubscribe = this.getFeatures().subscribe(
      (response) => {
        this.features = response || [];
      },
      (error) => {
        this.features = [];
        this.log.e('Failed API feature', error);
      }
    );
  }

  getFeatures(): Observable<Feature[]> {
    const featuresUrl = `${this.path}features`;

    // If cached, return it
    if (this.featureCache$) { return this.featureCache$; }

    // Cache the observable result
    this.featureCache$ = this.memoizationService.memoize('memoize_feature', () =>
      this.httpClient.get<Feature[]>(featuresUrl).pipe(
        retryWhen((errors) =>
          errors.pipe(
          // Retry up to 3 times with a delay of 1 second between retries
            delay(1000),
            take(3),
            catchError((error) => {
              this.log.e('API request error', error);
              return of([]); // Return empty array on error
            })
          )
        ),
        shareReplay(1) // Cache and share the last emitted value
      )
    );

    return this.featureCache$;
  }

  private isExpiredData(): boolean {
    return this.user.id !== this.previousUserId;
  }

  public async getFeatureConfigs(): Promise<any> {
    const featureFlag = {};
    let sku = 'free';

    if (!this.user['is_link_user']) {
      sku = await this.skuService.getSku();
      this.user.sku = sku;
    } else {
      this.user.sku = this.user.sku || sku;
    }

    if (this.features && this.features.length > 0) {
      this.features.forEach((feature) => {
        featureFlag[feature.feature_name] =
          feature.enabled && this.isFeatureAllowed(feature, this.user);
      });
    } else {
      this.getFeatureData();
    }

    return featureFlag;
  }

  public async isAllowed(featureName: string): Promise<boolean> {
    await this.getUser();
    if (!this.user) {
      return false;
    }

    if (!this.featureFlag || this.isExpiredData()) {
      this.featureFlag = await this.getFeatureConfigs();
    }
    return this.featureFlag[featureName] || false;
  }

  private isFeatureAllowed(feature: Feature, user: User): boolean {
    return (
      this.isValueEndsWithCriteria(feature.userid_criteria, user.id) &&
      this.isValueEndsWithCriteria(feature.email_criteria, user.email) && // Email criteria won't work for links. Refer: COM-5015
      this.isValueHasCriteria(feature.plan_criteria, user.sku) && // Plan criteria won't work for links Refer: COM-5015
      this.isValueEndsWithCriteria(feature.teams_criteria, user.team_id) &&
      this.isValueHasCriteria(feature.country_criteria, Intl.DateTimeFormat().resolvedOptions().timeZone) &&
      this.isUserIdMatchingModulo(feature.percent_criteria, user.id) &&
      this.isUserInShard(feature.shard_criteria, user.id)
    );
  }

  private isValueEndsWithCriteria(criteria: string, value: number | string): boolean {
    value = value || '';
    return criteria ? new RegExp(`(${criteria})$`, 'i').test(value.toString()) : true;
  }

  private isValueHasCriteria(criteria: string, sku: string): boolean {
    return criteria ? new RegExp(`(${criteria})`, 'i').test(sku) : true;
  }

  public isUserIdMatchingModulo(criteria: string, userId: number): boolean {
    if (criteria) {
      const criteriaNumber = Number(criteria);
      let percent;
      // For legacy users. userIds less than 1 million.
      if (userId < 1000000) {
        percent = userId % 100;
      } else {
        percent = (userId / 10000) % 100;
      }
      return percent <= criteriaNumber;
    }
    // return true if criteria is not provided/empty
    return true;
  }

  public isUserInShard(criteria: string, userId: number): boolean {
    if (criteria) {
      const criteriaArray = criteria.split('|').map(c => c.trim());

      // For legacy users. userIds less than 1 million.
      if (userId < 1000000) {
        if (criteriaArray.includes('legacy')) {
          return true;
        }
      } else {
        return criteriaArray.includes(String(userId % 10000));
      }
    }
    // return true if criteria is not provided/empty
    return true;
  }

  public resetFeatureFlags(): void {
    this.featureFlag = null;
    this.user = null;
    this.featureCache$ = null; // Reset the cache
  }

  private unsubscribe(subscription: Subscription | null): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
}
