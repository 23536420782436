import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../core/api.service';
import { UserService } from '../core/user.service';
import { UserInfoSetApiOutput, BlendEvent } from '../shared/models';
import { BlendService } from '../shared/services/blend.service';
import * as fromRoot from '../reducers';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sync-new-ui-opt-out',
  templateUrl: './new-ui-opt-out.component.html',
})
export class NewUiOptOutComponent implements OnInit {

  private subscription: Subscription;
  public isLoading = true;
  constructor(
    private userService: UserService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userService.refresh();
    this.subscription = this.store.pipe(select(fromRoot.getAuthUser))
      .subscribe(async (data) => {
        if (data.is_newui_disabled) {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          this.router.navigate(['/files']);
        }
      });
  }

}
