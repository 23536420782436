<div class="container-fluid container-fluid-wrap tab-wrapper">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">Account settings</div>

            <div class="right-menu-button right-menu-button-push affix"></div>

            <ul class="nav nav-tabs">
                <li role="presentation" class="active">
                    <a routerLink="/account/info">Account</a>
                </li>
                <li role="presentation">
                    <a routerLink="/account/billing">Billing</a>
                </li>
                <li role="presentation">
                    <a routerLink="/account/devices">Devices</a>
                </li>
                <li role="presentation" *ngIf="user.new_feature">
                    <a routerLink="/account/apps">Apps</a>
                </li>
                <li role="presentation">
                    <a routerLink="/account/security">Security</a>
                </li>
            </ul>
        </div>
        <!-- col-sm-12 affix -->
    </div>
    <!-- row -->

    <div class="row">
        <div class="col-sm-12 content-box-withmenu content-account">
            <sync-verify-email-required
                *ngIf="!user.is_verified"
            ></sync-verify-email-required>

            <sync-billing-status [user]="user"></sync-billing-status>

            <hr />

            <div class="row">
                <div class="col-sm-3">Storage:</div>
                <div class="col-sm-9">
                    Total {{ user.disk_usage_bytes | bytesToSize }} {{ checkIfUnlimited() ? '' : 'of ' + ( user.disk_limit_bytes | bytesToSize ) }}
                    <div class="progress account-progress" *ngIf="!checkIfUnlimited()">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            [ngStyle]="{ width: du.syncpercent + '%' }"
                        ></div>
                        <div
                            class="progress-bar"
                            role="progressbar"
                            style="background-color: #5d90b2"
                            [ngStyle]="{ width: du.vaultpercent + '%' }"
                        ></div>
                        <!-- <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:30%;background-color:#5d90b2"></div> -->
                    </div>

                    <div *ngIf="checkIfUnlimited()" class="infinity-space">
                        &infin;
                    </div>

                    <span style="margin-right: 16px">
                        <i class="fas fa-square" style="color: #0d3b66"></i>
                        Sync folder (
                        <i
                            *ngIf="du.status == DU_STATUS_INIT"
                            class="fa fa-spinner fa-spin"
                        ></i>
                        <span *ngIf="du.status != DU_STATUS_INIT">{{
                            du.syncbytes | bytesToSize
                        }}</span>
                        )
                    </span>
                    <span
                        style="margin-right: 16px"
                        *ngIf="du.status != DU_STATUS_INVALID"
                    >
                        <i class="fas fa-square" style="color: #5d90b2"></i>
                        Vault (
                        <i
                            *ngIf="du.status == DU_STATUS_INIT"
                            class="fa fa-spinner fa-spin"
                        ></i>
                        <span *ngIf="du.status == DU_STATUS_SUCCESS">{{
                            du.vaultbytes | bytesToSize
                        }}</span>
                        )
                    </span>
                    <span style="margin-right: 16px" *ngIf="!checkIfUnlimited()">
                        <i class="fas fa-square" style="color: #c2c2c2"></i>
                        Unused ({{ du.unusedbytes | bytesToSize }})
                    </span>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Display name:</div>
                <div class="col-sm-9" id="field-display-name">
                    {{ user.display_name | b64 }}
                    <a
                        (click)="openDisplaynameDialog()"
                        class="syncblue pull-right showhand"
                        id="display-name-edit-btn"
                        >Edit</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Display avatar:</div>
                <div class="col-sm-9" id="field-display-avatar">
                    <img *ngIf="avatarUrl"
                        class="img-circle display-avatar-setting"
                        [src]="avatarUrl"
                        alt="avatar"
                        (error)="handleMissingImage($event)"
                    />
                    <!-- <img
                        class="img-circle display-avatar-setting"
                        [src]="'data:image/png;base64,' + tempAvatar"
                        onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                    /> -->
                    <a
                        (click)="openDisplayAvatarDialog()"
                        class="syncblue pull-right showhand"
                        >Edit</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Email address:</div>
                <div class="col-sm-9" id="field-display-email">
                    {{ user.email }}
                    <a
                        (click)="openChangeUsernameDialog()"
                        class="syncblue pull-right showhand"
                        id="display-email-edit-btn"
                        >Edit</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Member since:</div>
                <div class="col-sm-9">
                    {{ user.join_date | date: "fullDate" }}
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Ref code:</div>
                <div class="col-sm-9">{{ user.referral_code }}</div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Sync rewards:</div>
                <div class="col-sm-7">
                    <span class="subtext">Your referral link:</span>
                    <div>
                        <a
                            class="syncblue showhand"
                            (click)="copyReferral()"
                            id="referral-link"
                        >
                            https://www.sync.com/?_sync_refer={{
                                user.referral_code
                            }}
                        </a>
                        <span *ngIf="showCopiedText">
                            Link copied to clipboard!
                            <i
                                class="fas fa-times-circle showhand"
                                (click)="showCopiedText = false"
                            ></i>
                        </span>
                        <!-- <button class="btn btn-primary btn-xs btn-rewards" (click)="invite()">
                            <i class="fas fa-share-alt"></i>
                            Share
                        </button> -->
                    </div>

                    <div
                        class="earned-it"
                        *ngIf="userRewards && userRewards.total"
                    >
                        <strong
                            >You have earned
                            {{ userRewards.total | bytesToSize }}!</strong
                        >
                    </div>
                </div>
                <div class="col-sm-2">
                    <a
                        (click)="openRewardsDialog()"
                        class="syncblue pull-right showhand"
                        >Rewards History</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Email preferences:</div>
                <div class="col-sm-9">
                    <a
                        (click)="openEmailPrefDialog()"
                        class="syncblue pull-right showhand"
                        >Edit</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Custom Branding:</div>
                <div class="col-sm-9">
                    <a
                        (click)="openCustomBrandDialog()"
                        class="syncblue pull-right showhand"
                        >Edit</a
                    >
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-3">Previewer preferences:</div>
                <div class="col-sm-8">
                    <span>{{ user.is_office ? "Office Online" : "Sync" }}</span>
                </div>
                <div class="col-sm-1">
                    <a
                        (click)="openPreviewPreferences()"
                        class="syncblue pull-right showhand"
                        >Edit</a
                    >
                </div>
            </div>

            <hr *ngIf="allowed()" />

            <div class="row" *ngIf="allowed()">
                <div class="col-sm-3">Close account:</div>
                <div class="col-sm-9" id="delete-account-btn">
                    <a
                        (click)="openCancelDialog()"
                        class="syncblue pull-right showhand"
                        id="delete-account-btn"
                        >Delete account</a
                    >
                </div>
            </div>
        </div>
        <!-- content box -->
    </div>
    <!-- row-->
</div>
