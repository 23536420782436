import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SupportTicketService {

    constructor() { }


    public sendSupportTicket(params: any) {
        let key, field;
        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', 'https://www10.sync.com/support-submit.php');

        for (key in params) {
            if (params.hasOwnProperty(key)) {
                if (key == 'z_description') {
                    field = document.createElement('textarea');
                    field.setAttribute('name', key);
                    field.value = params[key];
                    // field.setAttribute('value', params[key]);

                } else {
                    field = document.createElement('input');
                    field.setAttribute('type', 'hidden');
                    field.setAttribute('name', key);
                    field.setAttribute('value', params[key]);
                }
                form.appendChild(field);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }
}
