import { ErrCode } from '../err-code.model';
import { BaseApiOutput } from './base-api.model';
import { LinkApiOutput } from './linkget-api.model';

export class LinkPathListApiOutput extends BaseApiOutput {
    oid: number; // owner id
    is_pro: 1|0;
    is_business: 1|0;
    share_id: number;
    sync_id: string;
    root_sync_id: string;
    compat: 1|0;
    previewonly: 1|0;
    file_edit: 1 | 0;
    upload: 0|1|2;
    linkversion: number;
    iterations: number;
    salt: string;
    pathitems: sync.IFile[];
    servers: sync.IServerAddresses;
    servers_web: string[];
    servers_preview: string[];
    servers_compat: string[];
    cwd: sync.IFile;
    //whitelabel attributes
    image_cachekey: string;
    header_primary_color: string;
    header_text_color: string;
    button_primary_color: string;
    button_text_color: string;
    link_text_color: string;
    url?: string;
    url_dl?: string;
    //comment feature
    allow_comment: 0 | 1 | 2;
    enc_comment_key: string;
    comment_notification: 0 | 1;
    //owner info
    id: number;
    team_id: number;
    is_multi_admin: boolean;
    is_on_trial: boolean;
}

export class LinkPathListApiInput {
    sync_id: number;
    publink_id: string;
    passwordlock?: string;
    key: string;
}


