import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { BrowserSupportService } from '../../core/browser-support.service';
import { LoggerService } from '../../core/logger.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { getFileExt } from '../../shared/func';
import { BlendEvent, ErrCode } from '../../shared/models';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { TransferStatus } from '../../transfer/transfer.model';
import { TransferService } from '../../transfer/transfer.service';
import { BlendService } from '../../shared/services/blend.service';

interface ItemQueue {
    idx: number;
    amount: number;
    current: sync.IFile;
    nextItem: sync.IFile;
    prevItem: sync.IFile;
}

@Component({
    selector: 'sync-preview-image',
    templateUrl: './preview-image.component.html',
})
export class PreviewImageComponent implements OnInit, OnDestroy {
    @Input() public type: string; // binding from component.
    public items: ItemQueue = {
        idx: 1,
        amount: 0,
        current: null,
        nextItem: null,
        prevItem: null,
    };

    public transferView: sync.ITransferView;
    public errorMsg: string;
    public btFile = { renderFile: { dl_ready: false } };
    public btFileSpinner = false;
    public canSave: boolean;

    public fullScreen = false;
    public spinner = false;

    public transferItem: sync.ITransferItemDownload;

    public allowComment: 0 | 1 | 2;

    public errcode: ErrCode;
    public initialized = false;
    public ctx: string;
    public isCommentExpanded: boolean;
    public imageURL: SafeUrl;

    @Input() public item: sync.IFile;
    @Input() public list: sync.IFile[];
    @Input() private syncId: number;
    private sub: Subscription;

    constructor(
        private BrowserSupport: BrowserSupportService,
        private Logger: LoggerService,
        private transferService: TransferService,
        private buildTransferItemService: BuildTransferItemService,
        private LinkPathList: LinkFileListService,
        private sanitizer: DomSanitizer,
        private api: ApiService,
        private broadcastService: BroadcastService,
        private blendService: BlendService,
    ) { }


    bypassSecurityTrustUrl() {
        if (this.transferView.single.renderFile.url) {
            this.imageURL = this.sanitizer.bypassSecurityTrustUrl(this.transferView.single.renderFile.url);
        }
        if (this.item.compaturl) {
            this.imageURL = this.sanitizer.bypassSecurityTrustUrl(this.item.compaturl);
        }
    }

    ngOnInit() {
        this.transferView = this.buildTransferItemService.view;
        this.canSave = this.BrowserSupport.getFileSaveMethod() != null;
        this.errorMsg = '';
        this.broadcastService.on('event:keydown:37').subscribe(() => this.navigatePrev());
        this.broadcastService.on('event:keydown:39').subscribe(() => this.navigateNext());
        this.sub = this.LinkPathList.getSubscription().subscribe((data) => {
            if (data.loaded && data.sorted) {
                this.allowComment = data.allow_comment;
            }
        });
        this.blendService.track(BlendEvent.VIEW_FILE, {
            type: 'image',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
        this.initialize();
    }

    ngOnDestroy() {
        this.buildTransferItemService.view.single = undefined;
        this.initialized = false;
        this.transferItem = undefined;
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    private async initialize() {
        this.initialized = false;
        this.transferItem = undefined;
        this.navigate(this.item);
    }

    public isOpenable() {
        let openable = false;
        switch (getFileExt(this.items.current.name)) {
            case 'pdf':
            case 'txt':
            case 'md':
            case 'jpg':
            case 'gif':
            case 'png':
                openable = true;
                break;
            default:
                openable = false;
        }
        return openable;
    }

    public async open() {
        let link;
        if (this.items.current.compat && this.items.current.context == 'link') {
            const compatUrl = await this.buildTransferItemService.getCompatUrl(
                this.items.current,
                this.buildTransferItemService.ACT_PREVIEW
            );
            link = compatUrl;
        } else if (
            this.items.current.compat &&
            this.items.current.context == 'applink'
        ) {
            link = this.items.current.compaturl;
        } else {
            const tItem = await this.transferService.getFile(this.items.current, true);
            link = tItem.renderFile.url;
        }
        window.open(link, '_blank');
    }

    public async downloadOriginal() {
        this.item.blobtype = 'btFILE';
        if (this.item.compat && this.item.context == 'link') {
            const url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_DOWNLOAD
            );
            window.open(url, '_blank');
        } else if (this.item.context == 'link') {
            this.transferService.queueDownload([this.item]);
        } else if (this.item.compat && this.item.context == 'applink') {
            window.open(this.item.compaturl_dl, '_blank');
        } else {
            this.transferService.queueDownload([this.item]);
        }
    }

    private async watch(pathitem: sync.IFile) {
        this.transferItem = undefined;
        this.initialized = false;
        if (pathitem) {
            try {
                this.btFile = { renderFile: { dl_ready: false } };
                pathitem.blobtype =
                    pathitem.has_thumb2 && pathitem.size > 100 * 1024
                        ? 'btTHUMB2'
                        : 'btFILE';
                // pathitem.transferType = TransferItem.TYPE_PREVIEW;
                const tItem = await this.transferService.getFile(pathitem, true);
                // GetFile for appLink doesn't properly set this.Transferview.single for URL in open.
                if (
                    tItem.status == TransferStatus.STATUS_ERR_SIZE ||
                    tItem.status == TransferStatus.STATUS_ERR_OPEN
                ) {
                    // Logger.error('Cannot view file');
                    this.errorMsg = 'Unable to view this file.';
                    this.transferView.cannotViewSave = true;
                }
                if (tItem.linkID) {
                    this.Logger.info('Consume link');
                    this.api.execute('linkconsume', {
                        links: [{ publink_id: tItem.linkID }],
                        is_image_preview: 1,
                    });
                }
                this.initialized = true;
            } catch (err) {
                this.errcode = ErrCode.fromException(err);
                this.transferView.cannotViewSave = true;
                if (err.errors) {
                    this.errorMsg = err.errors[0].error_msg;
                } else {
                    this.errorMsg = 'Unable to view this file.';
                }
            }
        } else {
            this.initialized = true;
            this.item = undefined;
            this.btFileSpinner = false;
            this.btFile = { renderFile: { dl_ready: false } };
            this.errorMsg = 'Invalid file provided';
        }
        this.bypassSecurityTrustUrl();
    }

    public navigate(item: sync.IFile) {
        this.items.current = item;
        this.watch(item);
        this.getNextPrevItems(item);
    }

    public navigateNext() {
        if (this.items.nextItem) {
            this.navigate(this.items.nextItem);
        }
    }

    public navigatePrev() {
        if (this.items.prevItem) {
            this.navigate(this.items.prevItem);
        }
    }
    private getNextPrevItems(curItem: sync.IFile) {
        this.items.amount = this.list.length;
        for (let i = 0; i < this.items.amount; i++) {
            if (this.list[i].sync_id == curItem.sync_id) {
                this.items.idx = i + 1;
                this.items.prevItem = this.list[i - 1]
                    ? this.list[i - 1]
                    : undefined;
                this.items.nextItem = this.list[i + 1]
                    ? this.list[i + 1]
                    : undefined;
                break;
            }
        }
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }
}
