<nav class="navbar navbar-default navbar-fixed-top">
    <div class="navbar-header">
        <a class="navbar-brand" routerLink="/files">
            <img id="navbar-logo" src="images/sync-q-darkbg.svg" />
        </a>
    </div>
    <div id="navbar" class="n">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active" class="hidden-xs">
                <a id="navbar-files-tab" routerLink="/files">Files</a>
            </li>
            <li routerLinkActive="active" class="hidden-xs">
                <a id="navbar-vault-tab" routerLink="/vault">Vault</a>
            </li>
            <li routerLinkActive="active" class="hidden-xs">
                <a id="navbar-starred-tab" routerLink="/starred">Starred</a>
            </li>
            <li ngbDropdown class="dropdown" class="hidden-xs">
                <a ngbDropdownToggle class="dropdown-toggle" id="sharing-path-btn" role="button" aria-haspopup="true"
                    aria-expanded="false">Sharing <span class="caret"></span></a>
                <ul ngbDropdownMenu role="menu" class="dropdown-menu dropdown-regular">
                    <li>
                        <a id="navbar-shares-tab" routerLink="/shares">
                            Team Shares
                            <span *ngIf="pendingShares > 0" class="badge nav-badge">
                                {{ pendingShares }}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a id="navbar-links-tab" routerLink="/links">Links</a>
                    </li>
                </ul>
            </li>
            <li routerLinkActive="active" *ngIf="featureService?.featureFlag?.recents" class="hidden-xs">
                <a id="navbar-recents-tab" routerLink="/recents">Recents</a>
            </li>
            <li routerLinkActive="active" id="event-path-btn" class="hidden-xs">
                <a routerLink="/events">
                    Events
                    <!-- <span ng-show="eventCount" class="badge nav-badge">
                        {{eventCount}}
                    </span> -->
                </a>
            </li>
            <li routerLinkActive="active" class="hidden-xs" id="users-path-btn" *ngIf="checkPermission(permissionActions.VIEW_USERS) || checkPermission(permissionActions.VIEW_ROLES)">
                <a (click)="onUsersClick()">Users</a>
            </li>
        </ul>

        <ul class="nav navbar-nav navbar-right">
            <li *ngIf="user" class="dropdown" ngbDropdown>
                <a ngbDropdownToggle class="dropdown-toggle navrightdrop" id="user-display-name-dropdown" data-toggle="dropdown">
                    <div class="tdmore">
                        <span class="hidden-xs">{{ user.display_name | b64 }}
                        </span>
                        <i class="hidden-xs zmdi zmdi-chevron-down zmdi-hc-lg"></i><i
                            class="hidden-sm hidden-md hidden-lg fa-lg fas fa-bars"></i>
                    </div>
                </a>
                <!-- seems like an issue with B4 positioning https://github.com/ng-bootstrap/ng-bootstrap/issues/1747 -->
                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-left dropdown-regular navrightdrop-ul">
                    <li>
                        <div class="account-dropdown-container">
                            <div class="tdmore account-dropdown-user">
                                {{ user.email }}
                            </div>
                            <!-- disk usage component-->
                            <div class="hidden-xs progress account-dropdown-progress">
                                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{
                                        width: user.disk_usage_percent + '%'
                                    }"></div>
                            </div>
                            <span class="hidden-xs account-dropdown-quota">
                                {{ user.disk_usage_bytes | bytesToSize }} of
                                {{ user.is_unlimited_plan ? "Unlimited" : (user.disk_limit_bytes | bytesToSize) }}
                                <ng-container *ngIf="!user.is_unlimited_plan">used</ng-container></span>
                            <!-- disk usage component-->
                        </div>
                    </li>
                    <li *ngIf="checkPermission(permissionActions.MANAGE_BILLING)">
                        <a (click)="onUpgradeClick()" class="syncblue">
                            {{user.is_on_trial && zprofile ? (!zprofile.defaultPaymentMethod ? "Add billing profile" : "Change plan") : "Upgrade"}}
                        </a>
                    </li>

                    <!-- only for XS screen -->
                    <li role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
                    <li class="hidden-sm hidden-md hidden-lg">
                        <a routerLink="/files">Files</a>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg">
                        <a routerLink="/vault">Vault</a>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg">
                        <a routerLink="/starred">Starred</a>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg">
                        <a routerLink="/shares">Sharing</a>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg" *ngIf="featureService?.featureFlag?.recents">
                        <a routerLink="/recents">Recents</a>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg">
                        <a routerLink="/events">Events</a>
                    </li>
                    <li *ngIf="checkPermission(permissionActions.VIEW_USERS) || checkPermission(permissionActions.VIEW_ROLES)"
                        class="hidden-sm hidden-md hidden-lg">
                        <a (click)="onUsersClick()">Users</a>
                    </li>

                    <!-- only for XS screen -->

                    <li role="separator" class="divider"></li>

                    <li id="acount-settings-btn"><a routerLink="/account/info">Settings</a></li>
                    <li>
                        <a href="https://www.sync.com/install">Install apps</a>
                    </li>

                    <li role="separator" class="divider"></li>
                    <li *ngIf="featureService?.featureFlag?.newUI || user.has_beta_access == 1">
                        <a (click)="toggleNewUi()" class="try-new-ui">{{user.is_newui_disabled ? 'Try new UI' : 'Switch to Old Interface'}} 
                            <span class="badge syncblue beta" *ngIf="user.is_newui_disabled">Beta</span></a>
                    </li>
                    <li *ngIf="featureService?.featureFlag?.newUI || user.has_beta_access == 1" role="separator" class="divider"></li>

                    <li id="browse-get-help-btn"><a (click)="openContactSupport('get_help')">Get Help</a></li>

                    <li id="acount-sign-out-btn"><a routerLink="/logout">Sign out</a></li>
                </ul>
            </li>
            <li class="hidden-xs" id="browse-help-btn" *ngIf="user.is_pro">
                <a (click)="openContactSupport('help')">Help</a>
            </li>
        </ul>
    </div>
</nav>
