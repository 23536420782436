import { Component, OnInit, OnDestroy, AfterViewInit, SecurityContext } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { User, ErrCode, OrderUpgradesApiOutput, PlanDetails, PermissionActions, BlendEvent } from '../../shared/models';
import { Subscription } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogMultiuserAddComponent } from '../../multi-user/dialog-multiuser-add/dialog-multiuser-add.component';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { UserService } from '../../core/user.service';
import { SkuService } from '../../shared/services/sku.service';
import { MultiAdminService } from '../../core/multiadmin.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { PlanTerm } from '../../shared/models/api/plandetails.model';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmPasswordComponent } from '../dialog-confirm-password/dialog-confirm-password.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BlendService } from 'src/app/shared/services/blend.service';

@Component({
    selector: 'sync-upgrade',
    templateUrl: './upgrade.component.html',
    providers: [MultiAdminService]
})
export class UpgradeComponent implements OnInit, AfterViewInit, OnDestroy {

    public user: User;

    public view: 'teams' | 'individual' = 'teams';
    public viewTerm: 'annual' | 'monthly' = 'annual';

    private sub: Subscription;

    public spinner: boolean;
    public errcode: ErrCode;

    public userLimit: number[];
    public teamsQty: number;

    private sessionId: string;
    private encHash: string;
    public products;
    public cancelInFuture: boolean;
    public unpaidInvoices: boolean;
    public teamsSubUser: boolean;

    public isZuoraUpgrade = false;
    public isTeamsOnTrial = false;
    public isTrialUserWithoutCC = false;
    private isTeamsPlusUser = false;
    private showOrderFormCalled = false;
    public planterm: string;
    public upgradePromoHtml: SafeHtml = '';

    public selectedProduct: {
        annual?: {
            PS?: any;
            PER?: any;
            BPT?: any;
        },
        monthly?: {
            PS?: any;
            PER?: any;
            BPT?: any;
        }
    } = {
            annual: {},
            monthly: {}
        };
    public selectedTrialPlan: {
        PS?: any;
        PER?: any;
        BPT?: any;
    } = {};

    public displayProductsY: {
        PS?: any[];
        BPT?: any[];
        PER?: any[];
    } = {
            PS: [],
            BPT: [],
            PER: []
        };

    public displayProductsM: {
        PS?: any[];
        BPT?: any[];
        PER?: any[];
    } = {
            PS: [],
            BPT: [],
            PER: []
        };

    public personalPlan: PlanDetails = {
        name: 'Personal',
        family: 'PER',
        isPro: false,
        features: [
            '200 GB storage space'
        ],
        annual: {},
        monthly: {},
        trialPlan: {}
    };
    public soloBasicPlan: PlanDetails = {
        name: 'Solo Basic',
        family: 'PS',
        isPro: true,
        features: [
            '2 TB (2,000 GB) storage space',
            'Document previews',
            'File requests',
            'Sync CloudFiles on-demand'
        ],
        annual: {},
        monthly: {},
        trialPlan: {}
    };
    public soloProfessionalPlan: PlanDetails = {
        name: 'Solo Professional',
        family: 'PS',
        isPro: true,
        features: [
            '6 TB storage space',
            'Document previews',
            'File requests',
            'Sync CloudFiles on-demand',
            'Custom branding',
            'Priority support'
        ],
        annual: {},
        monthly: {},
        trialPlan: {}
    };
    public teamsStandardPlan: PlanDetails = {
        name: 'Teams Standard',
        family: 'BPT',
        isPro: true,
        features: [
            '1 TB storage space per user',
            'Administrator account',
            'Document previews',
            'File requests',
            'Sync CloudFiles on-demand'
        ],
        annual: {},
        monthly: {},
        trialPlan: {}
    };
    public teamsUnlimited: PlanDetails = {
        name: 'Teams Unlimited',
        family: 'BPT',
        isPro: true,
        features: [
            'Unlimited storage space',
            'Administrator account',
            'Document previews',
            'File requests',
            'Sync CloudFiles on-demand',
            'Custom branding',
            'Phone support'
        ],
        annual: {},
        monthly: {},
        trialPlan: {}
    };
    public currentTrialPlan: PlanDetails;
    public trialPlanExpiry: number;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private syncCookie: SyncCookieService,
        private zuora: ZuoraAccountService,
        private userService: UserService,
        private skuService: SkuService,
        private syncCrypt: SyncCryptService,
        private multiadminService: MultiAdminService,
        private route: ActivatedRoute,
        private sanitize: DomSanitizer,
        private cookieService: SyncCookieService,
        private blendService: BlendService
    ) { }

    ngOnInit() {
        this.spinner = true;
        this.sub = this.store
            .select(fromRoot.getAuthUser)
            .subscribe((data) => {
                if (data) {
                    this.user = data;
                    // @TODO once zuora is a go for all new orders:
                    // if (this.user.zuora_acct_key || this.user.plan_id === 1) {
                    if (this.user.zuora_acct_key
                        || this.route.snapshot.queryParams['forcezuora'] === 1
                        || this.user.plan_id === 1) {
                        this.teamsSubUser = this.user.is_multi_new ? !this.userService.checkPermission(PermissionActions.MANAGE_BILLING) : !this.user.is_multi_admin && this.user.is_multi_child;
                        this.isTeamsPlusUser = this.skuService.isTeamsPlusUser(this.user.sku);
                        if (!this.teamsSubUser && !this.showOrderFormCalled) {
                            this.showOrderFormCalled = true;
                            this.showOrderForm();
                        }
                    } else if (this.user.plan_id !== 1) {
                        // showing an error to contact billing for assistance since
                        // upgrading from legacy system to Zuora requires manual work
                        this.errcode = new ErrCode(8505);
                        this.spinner = false;
                    } else {
                        this.errcode = new ErrCode(8506);
                        this.spinner = false;
                    }
                }
            });
    }

    async ngAfterViewInit() {
        const isFreeUser = await this.skuService.isFreeUser();
        if (isFreeUser) {
            try {
                const url = `${environment.upgradePromoBannerUrl}?syncuid=${this.user.uid}`;
                const html = await this.api.fetchText(url);
                this.upgradePromoHtml = this.sanitize.bypassSecurityTrustHtml(html);
            } catch {
                this.upgradePromoHtml = '';
            }
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    /**
     * Called from the template.
     * @param family String the product sku
     * @param rp rate plan id
     * @param qty quantity for multi user
     * @returns
     */
    public onUpgrade = async (family: string, rp: string, qty?: number, isTrial?: boolean, term?: string) => {
        const type = (this.user.is_pro)
            ? 'upgrade'
            : 'signup';
        const params = [
            `rp=${rp}`
        ];
        if (qty && qty > 1) {
            params.push(`qty=${qty}`);
        }
        if (type === 'signup') {
            params.push('hasacct=1');
        }
        if (isTrial) {
            params.push('allowtrial=1');
            // term ? params.push('termaftertrial=' + term) : null;
            params.push('payrequired=0');
        }
        if (this.user.is_on_trial) {
            params.push('ontrial=1');
        }

        if (this.products[family]) {
            // check yearly first
            let item;
            item = this.products[family].yearlyRatePlans.filter(item => item.id == rp);
            if (!item || item.length == 0) {
                item = this.products[family].monthlyRatePlans.filter(item => item.id == rp);
            }

            if (isTrial) {
                item = this.products[family].trialRatePlans.filter(item => item.id == rp);
            }
            if (!item || item.length == 0) {
                this.log.error(`Could not locate family: ${family} with rate plan id: ${rp} in ${JSON.stringify(this.products)}`);
                this.errcode = new ErrCode(8520);
                return;
            }

            if (this.skuService.isTeamsPlusUser(item[0].product_sku)) {
                params.push('cnc=1');
                if (!this.isTeamsPlusUser) {
                    await this.cookiesSetterForCNC();
                }
            }

            const productId = item[0].product_id;
            this.cookieService.setDisableAnalytics(this.userService.get('is_disableanalytics'));
            await this.blendService.track(BlendEvent.BUTTON_CLICKED, {
                Attribution_Mtags: this.cookieService.getMarketingRef(),
                'Page Name': 'Upgrade page',
                button_text: this.user.is_on_trial ? 'Change plan' : 'Buy now',
            });
            window.location.href = `${environment.ordershost}/${type}/${productId}?${params.join('&')}`;

        } else {
            this.log.error(`Could not locate family: ${family}`);
            this.errcode = new ErrCode(8520);
            return;
        }

    }

    /**
     * Throws on exception
     *  ex.code == 8024 - authentication credentials incorrect
     */
    private async cookiesSetterForCNC() {
        try {
            const password = await this.syncCrypt.userPasswordDecrypt(this.encHash);
            const sData = await this.multiadminService.getPwdKeysDataAuthenticated(this.userService.get('email'), password);
            const encryptedData = await this.syncCrypt.userkeyEncrypt(btoa(password), this.sessionId);
            this.syncCookie.setOrderSessionHashData(encryptedData);
            this.syncCookie.setOrderSessionSaltData(JSON.stringify(sData));

        } catch (ex) {
            if (ex instanceof ErrCode) {
                if (ex.code == 8024) {
                    const result: boolean | string = await this.confirmPassword();
                    if (typeof result === 'string') {
                        await this.fixUpgradePassword(result);
                        return this.cookiesSetterForCNC();
                    } else if (result === true) {
                        return this.cookiesSetterForCNC();
                    } else {
                        this.errcode = ex;
                    }
                } else if (ex.code == 2100) {
                    this.log.error('The enc_password field is incorrect and not decryptable');
                    this.errcode = new ErrCode(2123);

                    // spawn password dialog
                } else {
                    this.errcode = ex;
                }
            } else {
                this.errcode = new ErrCode(1000, 'An unhandled error occurred');
            }
            this.log.error(`Re-throwing exception in cookiesSetterForCNC`);
            throw this.errcode;
        }


    }

    public async showOrderForm(): Promise<void> {
        const zprofile = await this.zuora.getProfile();
        if (zprofile) {
            this.cancelInFuture = (zprofile.subscription && zprofile.subscription.status === 'Cancelled');
            this.unpaidInvoices = (zprofile.balance > 0 || zprofile.unpaidInvoiceCount > 0);
            this.trialPlanExpiry = zprofile.subscription && this.userService.dateConversionForTrials(zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
            this.isTrialUserWithoutCC = !zprofile.defaultPaymentMethod;
        }

        if (this.user.zuora_acct_key && this.user.zuora_acct_key !== '') {
            this.isZuoraUpgrade = true;
        }
        try {
            const result = await this.api.execute<OrderUpgradesApiOutput>('orderupgrades', {});
            if (result && result.sessionId) {
                this.syncCookie.setOrderSessionId(result.sessionId);
                this.sessionId = result.sessionId;
                this.encHash = result.encHash;
                this.products = result.products;
                if (this.route.snapshot.queryParams['payfortrial'] && this.user.is_on_trial) {
                    const skus = zprofile.subscription.sku.split('-');
                    for (const [key, value] of Object.entries(this.products[skus[0]])) {
                        this.products[skus[0]][key].forEach(p => {
                            if (p.product_sku === skus[0] && p.sku === skus[1]) {
                                this.onUpgrade(p.product_sku, p.id, parseInt(skus[skus.length - 1], 10));
                            }
                        });
                    }
                }

                // this property is being used to control which existing user can upgrade to black friday offer
                // this results to allow only existing free users to upgrade to new black friday offer rate plans
                // if user is already paid one, he will see the plans on upgrade page that he can upgrade to and
                // will not be redirected to orders page
                const bfControl = !((this.route.snapshot.queryParams['promocode'] === 'BFCM2024' || environment.blackFridayRatePLans.includes(this.route.snapshot.queryParams['rp'])) && this.user.is_pro);

                if (this.route.snapshot.queryParams['p'] && this.route.snapshot.queryParams['rp'] && !this.teamsSubUser && !this.unpaidInvoices && bfControl) {
                    const type = (this.user.is_pro) ? 'upgrade' : 'signup';
                    const params = [
                        `rp=${this.route.snapshot.queryParams['rp']}`,
                        'hasacct=1'
                    ];
                    if (this.route.snapshot.queryParams['qty'] && this.route.snapshot.queryParams['qty'] > 1) {
                        params.push(`qty=${this.route.snapshot.queryParams['qty']}`);
                    }
                    if (this.route.snapshot.queryParams['rpc']) {
                        params.push(`rpc=${this.route.snapshot.queryParams['rpc']}`);
                    }
                    if (this.route.snapshot.queryParams['promocode']) {
                        params.push(`promocode=${this.route.snapshot.queryParams['promocode']}`);
                    }
                    // showform can be '0' or '1' which in js translates to true/false.
                    if (this.route.snapshot.queryParams['showform'] != undefined && this.route.snapshot.queryParams['showform'] != '') {
                        params.push(`showform=${this.route.snapshot.queryParams['showform']}`);
                    }

                    // handle legacy style URLs
                    if (this.route.snapshot.queryParams['discp']) {
                        params.push(`discp=${this.route.snapshot.queryParams['discp']}`);
                    }
                    if (this.route.snapshot.queryParams['discrp']) {
                        params.push(`discrp=${this.route.snapshot.queryParams['discrp']}`);
                    }
                    if (this.route.snapshot.queryParams['discrpc']) {
                        params.push(`discrpc=${this.route.snapshot.queryParams['discrpc']}`);
                    }
                    if (this.route.snapshot.queryParams['cnc']) {
                        params.push(`cnc=${this.route.snapshot.queryParams['cnc']}`);
                        if (!this.isTeamsPlusUser) {
                            await this.cookiesSetterForCNC();
                        }
                    }
                    if (this.route.snapshot.queryParams['allowtrial']) {
                        params.push(`allowtrial=${this.route.snapshot.queryParams['allowtrial']}`);
                    }
                    if (this.route.snapshot.queryParams['payrequired']) {
                        params.push(`payrequired=${this.route.snapshot.queryParams['payrequired']}`);
                    }
                    if (this.route.snapshot.queryParams['min_user']) {
                        params.push(`min_user=${this.route.snapshot.queryParams['min_user']}`);
                    }

                    window.location.href = `${environment.ordershost}/${type}/${this.route.snapshot.queryParams['p']}?${params.join('&')}`;
                    return;
                }

                if (this.user.plan_user_limit < this.userService.MIN_USERS && !this.user.is_multi_new) {
                    this.teamsQty = this.userService.MIN_USERS;
                } else if (this.user.is_multi_new && zprofile && zprofile.subscription) {
                    this.teamsQty = zprofile.subscription.quantity;
                } else {
                    this.teamsQty = this.user.plan_user_limit;
                }
                this.userLimit = Array(this.userService.MAX_USERS - (this.teamsQty - 2)).fill(0).map((x, i) => i + this.teamsQty);

                this.planterm = this.user.is_multi_new ? this.skuService.getPlanTerm(zprofile.subscription.sku) : this.skuService.getPlanTerm(this.user.sku);

                const isOnAnnualPlan = this.planterm == PlanTerm.ANNUAL;
                const isOnMonthlyPlan = this.planterm == PlanTerm.MONTHLY;
                let canToggleToMonthly = true;
                if (isOnAnnualPlan && zprofile && zprofile.subscription) {
                    const endDate = this.userService.dateConversionForTrials(zprofile.subscription.termEndDate, this.user.is_on_trial);
                    const curDate = new Date();
                    if ((endDate.getTime() - curDate.getTime()) > 30 * 24 * 60 * 60 * 1000) {
                        canToggleToMonthly = false;
                    }
                }
                const showMonthlyPlan = (isOnMonthlyPlan || (isOnAnnualPlan && canToggleToMonthly)) || this.user.is_on_trial;
                const isUserOnTeamsPlus = (this.user.is_multi_new) || (this.user.is_pro && this.user.plan_user_limit === 1) || !(this.user.is_pro);
                this.isTeamsOnTrial = !(zprofile && zprofile.subscription && zprofile.subscription.quantity > 1 && this.user.is_on_trial);

                if (this.products.PER && this.isTeamsOnTrial) {
                    // personal annual plan
                    const personalPlanSku = '200G';
                    const personalProductSku = 'PER';
                    this.personalPlan.annual = this.products.PER.yearlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == personalPlanSku && plan.product_sku.toUpperCase() == personalProductSku
                    )[0];
                    this.personalPlan.trialPlan.annual = this.products.PER.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.personalPlan.annual.sku && trialRatePlan.product_sku === this.personalPlan.annual.product_sku
                    )[0];
                    this.personalPlan.trialPlan.monthly = this.products.PER.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.personalPlan.monthly.sku && trialRatePlan.product_sku === this.personalPlan.monthly.product_sku
                    )[0];
                }
                if (this.products.PS && this.isTeamsOnTrial) {
                    // Pro Solo annual + monthly plan
                    const soloBasicPlanSku = '2T';
                    const soloBasicProductSku = 'PS';
                    this.soloBasicPlan.annual = this.products.PS.yearlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == soloBasicPlanSku && plan.product_sku.toUpperCase() == soloBasicProductSku
                    )[0];
                    this.soloBasicPlan.monthly = showMonthlyPlan && this.products.PS.monthlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == soloBasicPlanSku && plan.product_sku.toUpperCase() == soloBasicProductSku
                    )[0];
                    this.soloBasicPlan.trialPlan.annual = this.products.PS.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.soloBasicPlan.annual.sku && trialRatePlan.product_sku === this.soloBasicPlan.annual.product_sku
                    )[0];
                    this.soloBasicPlan.trialPlan.monthly = showMonthlyPlan && this.products.PS.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.soloBasicPlan.monthly.sku && trialRatePlan.product_sku === this.soloBasicPlan.monthly.product_sku
                    )[0];

                    // Pro Solo Professional annual + monthly with trial plan
                    const soloProfessionalPlanSku = '6T';
                    const soloProfessionalProductSku = 'PSP';
                    this.soloProfessionalPlan.annual = this.products.PS.yearlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == soloProfessionalPlanSku && plan.product_sku.toUpperCase() == soloProfessionalProductSku
                    )[0];
                    this.soloProfessionalPlan.monthly = showMonthlyPlan && this.products.PS.monthlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == soloProfessionalPlanSku && plan.product_sku.toUpperCase() == soloProfessionalProductSku
                    )[0];
                    this.soloProfessionalPlan.trialPlan.annual = this.products.PS.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.soloProfessionalPlan.annual.sku && trialRatePlan.product_sku === this.soloProfessionalPlan.annual.product_sku
                    )[0];
                    this.soloProfessionalPlan.trialPlan.monthly = showMonthlyPlan && this.products.PS.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.soloProfessionalPlan.monthly.sku && trialRatePlan.product_sku === this.soloProfessionalPlan.monthly.product_sku
                    )[0];
                }
                if (this.products.BPT) {
                    // teams standard annual + monthly plan
                    const teamsStandardPlanSku = '1T';
                    const teamsStandardProductSku = isUserOnTeamsPlus ? 'BPTSP' : 'BPT';
                    this.teamsStandardPlan.annual = this.products.BPT.yearlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == teamsStandardPlanSku && plan.product_sku.toUpperCase() == teamsStandardProductSku
                    )[0];
                    this.teamsStandardPlan.monthly = showMonthlyPlan && this.products.BPT.monthlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == teamsStandardPlanSku && plan.product_sku.toUpperCase() == teamsStandardProductSku
                    )[0];
                    this.teamsStandardPlan.trialPlan.annual = this.products.BPT.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.teamsStandardPlan.annual.sku && trialRatePlan.product_sku === this.teamsStandardPlan.annual.product_sku
                    )[0];
                    this.teamsStandardPlan.trialPlan.monthly = showMonthlyPlan && this.products.BPT.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.teamsStandardPlan.monthly.sku && trialRatePlan.product_sku === this.teamsStandardPlan.monthly.product_sku
                    )[0];
                    if (isUserOnTeamsPlus) {
                        this.teamsStandardPlan.name = 'Teams+ Standard';
                    }

                    // teams unlimited annual + monthly with trial plan
                    const teamsUnlimitedPlanSku = 'UNLIMITED';
                    const teamsUnlimitedProductSku = isUserOnTeamsPlus ? 'BPTUP' : 'BPTU';
                    this.teamsUnlimited.annual = this.products.BPT.yearlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == teamsUnlimitedPlanSku && plan.product_sku.toUpperCase() == teamsUnlimitedProductSku
                    )[0];
                    this.teamsUnlimited.monthly = showMonthlyPlan && this.products.BPT.monthlyRatePlans.filter(
                        plan => plan.sku.toUpperCase() == teamsUnlimitedPlanSku && plan.product_sku.toUpperCase() == teamsUnlimitedProductSku
                    )[0];
                    this.teamsUnlimited.trialPlan.annual = this.products.BPT.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.teamsUnlimited.annual.sku && trialRatePlan.product_sku === this.teamsUnlimited.annual.product_sku
                    )[0];
                    this.teamsUnlimited.trialPlan.monthly = showMonthlyPlan && this.products.BPT.trialRatePlans.filter(
                        trialRatePlan => trialRatePlan.sku === this.teamsUnlimited.monthly.sku && trialRatePlan.product_sku === this.teamsUnlimited.monthly.product_sku
                    )[0];
                    if (isUserOnTeamsPlus) {
                        this.teamsUnlimited.name = 'Teams+ Unlimited';
                    }
                }

                if (this.user.is_on_trial) {
                    let sku = this.user.sku;
                    if (!sku) {
                        sku = zprofile && zprofile.subscription && zprofile.subscription.sku;
                    }
                    const [product, productSku] = sku.toUpperCase().split('-');
                    if ((product === 'BPT' || product === 'BPTSP') && productSku === '1T') {
                        this.currentTrialPlan = this.teamsStandardPlan;
                    } else if ((product === 'BPTU' || product === 'BPTUP') && productSku === 'UNLIMITED') {
                        this.currentTrialPlan = this.teamsUnlimited;
                    } else if ((product === 'PS' || product === 'PSP') && (productSku === '2T' || productSku === '6T')) {
                        this.currentTrialPlan = product === 'PS' ? this.soloBasicPlan : this.soloProfessionalPlan;
                    }
                }

                this.spinner = false;
            } else {
                this.spinner = false;
                this.errcode = new ErrCode(8520);
            }
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            this.log.error(`Loading the account upgrades page failed ${JSON.stringify(ex)}`);
        }
    }

    public addUsers(): void {
        const ref = this.modalService.open(DialogMultiuserAddComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.openState = 3; // should be an enum
        ref.componentInstance.addAction = 'order';
    }



    // COM-1188
    private async confirmPassword() {
        const ref = this.modalService.open(DialogConfirmPasswordComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
            // size: 'lg'
        });
        return ref.result;
    }

    // COM-1188
    private async fixUpgradePassword(password) {
        const encPassword = await this.syncCrypt.userpasswordEncrypt(password, this.user.pubkey);
        await this.api.execute('userinfoset', {
            enc_password: encPassword
        });
        this.encHash = encPassword;
    }
}
